var getCurrency = function (lang) {
    return lang === 'ru-RU' ? 'RUB' : 'USD';
};
var FORMATTER_LANG = 'en-US';
var PLURALS = {
    'en-US': { decimal: 'other', one: 'one', few: 'many', many: 'many' },
    'ru-RU': { decimal: 'other', one: 'one', few: 'few', many: 'many' },
};
var Formatter = /** @class */ (function () {
    function Formatter() {
    }
    Formatter.registerLanguageVersion = function (version) {
        FORMATTER_LANG = version;
    };
    Formatter.asNumber = function (value) {
        return Intl.NumberFormat(FORMATTER_LANG).format(value);
    };
    Formatter.asCurrency = function (value) {
        return Intl.NumberFormat(FORMATTER_LANG, {
            style: 'currency',
            currency: 'USD',
        }).format(value);
    };
    Formatter.asRealCurrency = function (value) {
        return Intl.NumberFormat(FORMATTER_LANG, {
            style: 'currency',
            currency: getCurrency(FORMATTER_LANG),
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };
    Formatter.asPercent = function (value) {
        return Intl.NumberFormat(FORMATTER_LANG, {
            style: 'percent',
        }).format(value);
    };
    Formatter.asDate = function (value) {
        return Intl.DateTimeFormat(FORMATTER_LANG, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        }).format(value);
    };
    Formatter.asTime = function (value) {
        return Intl.DateTimeFormat(FORMATTER_LANG, {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).format(value);
    };
    Formatter.asDateTime = function (value) {
        return Intl.DateTimeFormat(FORMATTER_LANG, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        }).format(value);
    };
    Formatter.asPlural = function (input, options) {
        var value = Number(input);
        for (var key in options) {
            if (!options.hasOwnProperty(key)) {
                continue;
            }
            if (/=\d+/.test(options[key])) {
                var compare = Number(options[key].substr(1));
                if (value === compare) {
                    return options[key].replace('#', Formatter.asNumber(value));
                }
            }
        }
        var plurals = PLURALS[FORMATTER_LANG];
        var type = plurals.many;
        switch (true) {
            case !(value % 1 === 0):
                type = plurals.decimal;
                break;
            case value > 10 && value < 15:
                type = plurals.many;
                break;
            case value % 10 === 1:
                type = plurals.one;
                break;
            case value % 10 > 1 && value % 10 < 5:
                type = plurals.few;
                break;
        }
        if (options.hasOwnProperty(type)) {
            return options[type].replace('#', Formatter.asNumber(value));
        }
        console.error('Invalid plural options', input, options);
        throw new Error('Invalid plural options');
    };
    return Formatter;
}());
export default Formatter;
