import { __awaiter, __generator } from "tslib";
import RequestError from "./RequestError";
export function getRequestData() {
    return window['requestData'];
}
var Auth = /** @class */ (function () {
    function Auth(params) {
        this.auth_endpoint = params.auth_endpoint;
    }
    Auth.prototype.login = function (email, password, csrfKey, csrfValue) {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                body = Auth.formData();
                body.append('email', email);
                body.append('password', password);
                if (csrfKey && csrfValue) {
                    body.append(csrfKey, csrfValue);
                }
                return [2 /*return*/, this.request('login', body)];
            });
        });
    };
    Auth.prototype.signup = function (email, password, csrfKey, csrfValue) {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                body = Auth.formData();
                body.append('mail', email);
                body.append('password', password);
                body.append('response_type', 'code');
                if (csrfKey && csrfValue) {
                    body.append(csrfKey, csrfValue);
                }
                return [2 /*return*/, this.request('signup', body)];
            });
        });
    };
    Auth.prototype.passwordReset = function (email, csrfKey, csrfValue) {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                body = Auth.formData();
                body.append('email', email);
                if (csrfKey && csrfValue) {
                    body.append(csrfKey, csrfValue);
                }
                return [2 /*return*/, this.request('reset-password', body)];
            });
        });
    };
    Auth.prototype.savePassword = function (password, token, csrfKey, csrfValue) {
        return __awaiter(this, void 0, void 0, function () {
            var body;
            return __generator(this, function (_a) {
                body = Auth.formData();
                body.append('password', password);
                body.append('resetToken', token);
                if (csrfKey && csrfValue) {
                    body.append(csrfKey, csrfValue);
                }
                return [2 /*return*/, this.request('save-password', body)];
            });
        });
    };
    Auth.formData = function () {
        return new FormData();
    };
    Auth.formBasicData = function () {
        return this.formData();
    };
    Auth.prototype.request = function (endpoint, body) {
        return fetch(this.auth_endpoint + endpoint, {
            method: 'POST',
            credentials: 'include',
            body: body
        })
            .then(function (response) {
            return response.json();
        })
            .then(function (response) {
            if (response.status !== 'ok') {
                throw new RequestError(response.message, response);
            }
            return response;
        });
    };
    return Auth;
}());
export default Auth;
