import Formatter from './Formatter';
// @ts-ignore
import en from "./en_US.json";
// @ts-ignore
import ru from "./ru_RU.json";
var DICTIONARIES = {
    'en-US': en,
    'ru-RU': ru,
};
var REGULARS = {
    main: new RegExp(/\{\s*([^,}]+)\s*(?:,\s*([^,}]+)\s*)?(,(?:\s*[\w=]+\{(?:\\\{|\\\}|[^}])+\})*)?\}/ug),
    options: new RegExp(/(?:\s*([\w=]+)\{((?:\\\{|\\\}|[^}])+)\})+?/ug),
    escape: new RegExp(/\\([{}])/ug),
};
var Messages = /** @class */ (function () {
    function Messages() {
    }
    Messages.getDictionary = function () {
        return DICTIONARIES[Messages.lang];
    };
    Messages.registerLanguageVersion = function (version) {
        Messages.lang = version;
    };
    /**
     * @param {String} category
     * @param {String} message
     * @param {Object?} params
     * @return {String}
     */
    Messages.t = function (category, message, params) {
        var dictionary = Messages.getDictionary();
        if (!dictionary.hasOwnProperty(category)) {
            return Messages.replace(message, params);
        }
        var dictCategory = dictionary[category];
        if (!dictCategory.hasOwnProperty(message)) {
            return Messages.replace(message, params);
        }
        return Messages.replace(dictCategory[message], params);
    };
    /**
     * @param {String} message
     * @param params
     * @return {String}
     */
    Messages.replace = function (message, params) {
        if (!params || !params.hasOwnProperty) {
            return message;
        }
        return message.replace(REGULARS.main, function (match, name, type, options) {
            if (!params.hasOwnProperty(name)) {
                return match;
            }
            switch (type) {
                case 'number':
                    return Formatter.asNumber(params[name]);
                case 'currency':
                    return Formatter.asCurrency(params[name]);
                case 'percent':
                    return Formatter.asPercent(params[name]);
                case 'date':
                    return Formatter.asDate(params[name]);
                case 'time':
                    return Formatter.asTime(params[name]);
                case 'dateTime':
                    return Formatter.asDateTime(params[name]);
                case 'plural':
                    var pluralOptions = Messages.parseOptions(options);
                    return Formatter.asPlural(params[name], pluralOptions);
                default:
                    return params[name];
            }
        });
    };
    Object.defineProperty(Messages, "formatter", {
        /**
         * @property {Formatter} formatter
         */
        get: function () {
            return Formatter;
        },
        enumerable: false,
        configurable: true
    });
    Messages.parseOptions = function (input) {
        var options = {};
        input.replace(REGULARS.options, function (match, name, value) {
            options[name] = value.replace(REGULARS.escape, function (match, value) {
                return value;
            });
        });
        return options;
    };
    Messages.lang = 'en-US';
    return Messages;
}());
export default Messages;
