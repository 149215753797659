<script>
    import Messages from "./helpers/messages/Messages";
    import Auth from "./helpers/Auth";

    export let language = 'en-US';
    export let auth_endpoint = '/auth/';

    export let csrfKey = '_csrf';
    export let csrfValue = null;

    Messages.registerLanguageVersion(language);

    let email;

    let mainError = null;
    let emailError = null;

    let loading = false;
    let showMailSend = false;

    const auth = new Auth({
        auth_endpoint
    });

    const onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        emailError = null;
        showMailSend = false;

        try {
            const response = await auth.passwordReset(email, csrfKey, csrfValue);
            if (response.status === 'ok') {
                showMailSend = true;
            }
        } catch (error) {
            if (error.response.data.errors) {
                if (error.response.data.errors.email) {
                    emailError = error.response.data.errors.email;
                }
            }
        }
    }
</script>

<header>
    <a href="/" class="logo {language}"></a>
</header>

<div class="clean-signup-form">
    {#if showMailSend}
        <div class='wrap'>
            <form action = "/auth/login" class="auth-form">
                <div style="font-size: 150%; text-align: center;">
                    {Messages.t('preset_form', 'MESSAGE_SUCCESS')}
                </div><br />
                <div class="field-node">
                    <button type="submit">{Messages.t('preset_form', 'BUTTON_SUCCESS')}</button>
                </div>
            </form>

        </div>
    {:else }
        <form class="auth-form" on:submit = {onSubmit}>
            {#if mainError}
                <div class="error">{mainError}</div>
            {/if}

            <h1 class="text-center">
                {Messages.t('preset_form', 'TITLE')}
            </h1>

            <p class="text-center">{Messages.t('preset_form', 'MESSAGE')}</p>

            <div class="field-node" class:error = {emailError}>
                <input placeholder={Messages.t('form', 'PLACEHOLDER_EMAIL')} type="text" name="email" id="email" autocomplete="off" bind:value={email} />
                {#if emailError}
                    <div class="error">{emailError}</div>
                {/if}
            </div>

            <div class="field-node">
                <button class:loading = {loading}>{Messages.t('preset_form', 'BUTTON')}</button>
            </div>
        </form>
    {/if}
</div>

<style lang="scss">
  @import "style";

  .logo {
    &.ru-RU {
      background: url(https://auth.instashpion.ru/themes/zengram/logo.png) no-repeat center;
    }

    &.en-US {
      background: url(https://auth.instashpion.ru/themes/zenpromo/logo.png) no-repeat center;
    }
  }
</style>