import { __extends } from "tslib";
var RequestError = /** @class */ (function (_super) {
    __extends(RequestError, _super);
    function RequestError(message, response) {
        var _this = _super.call(this, message) || this;
        _this.response = response;
        return _this;
    }
    return RequestError;
}(Error));
export default RequestError;
