<script>
    import Messages from "./helpers/messages/Messages";
    import Auth from "./helpers/Auth";

    export let language = 'en-US';
    export let auth_endpoint = '/auth/';

    export let csrfKey = '_csrf';
    export let csrfValue = null;

    Messages.registerLanguageVersion(language);

    let email = '';
    let password = '';

    let mainError = null;
    let emailError = null;
    let passwordError = null;

    let loading = false;

    const auth = new Auth({
        auth_endpoint
    });

    const signUp = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        emailError = null;
        passwordError = null;

        try {
            const response = await auth.signup(email, password, csrfKey, csrfValue);
            if (response.status === 'ok') {
                window.location.href = `/`;
            }
        } catch (error) {
            console.error(error);
            if (error.response.data.errors) {
                if (error.response.data.errors.password) {
                    passwordError = error.response.data.errors.password;
                }

                if (error.response.data.errors.mail) {
                    emailError = error.response.data.errors.mail;
                }
            }
        }
    }
</script>

<header>
    <a href="/" class="logo {language}"></a>
</header>

<div class="clean-signup-form">
    <form class="auth-form" on:submit = {signUp}>
        {#if mainError}
            <div class="error">{mainError}</div>
        {/if}

        <h1 class="text-center">
            {Messages.t('signup_form', 'TITLE')}
        </h1>

        <p class="text-center">{Messages.t('signup_form', 'MESSAGE')}</p>

        <div class="field-node" class:error = {emailError}>
            <input placeholder={Messages.t('form', 'PLACEHOLDER_EMAIL')} type="text" name="email" id="email" autocomplete="off" bind:value={email} />
            {#if emailError}
                <div class="error">{emailError}</div>
            {/if}
        </div>

        <div class="field-node" class:error = {passwordError}>
            <input placeholder={Messages.t('form', 'PLACEHOLDER_PASSWORD')} type="password" name="password" id="password" autocomplete="off" bind:value={password} />
            {#if passwordError}
                <div class="error">{passwordError}</div>
            {/if}
        </div>
        <div class="field-node">
            <button class:loading = {loading}>{Messages.t('signup_form', 'BUTTON')}</button>
        </div>
        <div>
            <div class="field-node text-center">{Messages.t('form', 'SOCIAL_MESSAGE')}</div>
            <div class="field-node text-center socials">
                <div class="ulogin-panel">
                    <div class="ulogin-buttons-container">
                        {#if language === 'ru-RU'}
                            <div on:click = {(e) => {window.location.href = e.target.getAttribute('data-href')}} data-href = "https://oauth.vk.com/authorize?client_id=7281535&redirect_uri=https%3A%2F%2Fauth.zengram.ru%2Foauth%2Fsocial%3Flanguage%3Dru-RU%26network%3Dvkontakte%26redirectUrl%3Dhttps%253A%252F%252Fzengram.ru%252Fauth%252Flogin&response_type=code&scope=email" class="ulogin-button-vkontakte " role="button" title="VK"></div>
                            <div on:click = {(e) => {window.location.href = e.target.getAttribute('data-href')}} data-href = "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=online&client_id=239064102104-clanfu6udga8akf3dga8e6v0fu3ed144.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fauth.zengram.ru%2Foauth%2Fsocial%3Flanguage%3Dru-RU%26network%3Dgoogle%26redirectUrl%3Dhttps%3A%2F%2Fzengram.ru%2Fauth%2Flogin&state&scope=email&approval_prompt=auto" class="ulogin-button-google" role="button" title="Google"></div>
                            <div on:click = {(e) => {window.location.href = e.target.getAttribute('data-href')}} data-href = "https://connect.ok.ru/oauth/authorize?client_id=512000333191&redirect_uri=https%3A%2F%2Fauth.zengram.ru%2Foauth%2Fsocial%3Flanguage%3Dru-RU%26network%3Dodnoklassniki%26redirectUrl%3Dhttps%3A%2F%2Fzengram.ru%2Fauth%2Flogin&response_type=code&scope=GET_EMAIL" class="ulogin-button-odnoklassniki" role="button" title="Odnoklassniki"></div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
        <div class="field-node text-center">
            {Messages.t('signup_form', 'LOGIN_PHRASE')} <a href="/auth/login">{Messages.t('signup_form', 'LOGIN_LINK')}</a>
        </div>
    </form>
</div>
{#if language === 'ru-RU'}
    <p class="text-center privacy-text">
        Совершая регистрацию Вы даете согласие на обработку персональной <br>
        информации на условиях, определенных <a href="/privacy.pdf">Политикой конфиденциальности</a>.
    </p>
{/if}

<style lang="scss">
  @import "style";

  .logo {
    &.ru-RU {
      background: url(https://auth.instashpion.ru/themes/zengram/logo.png) no-repeat center;
    }

    &.en-US {
      background: url(https://auth.instashpion.ru/themes/zenpromo/logo.png) no-repeat center;
    }
  }
</style>