<script>
import { post } from "./lib/Request.svelte";
import RangeSlider from "svelte-range-slider-pips";

let queryString;
let searchResults = [];
let showCalcPanel = false;
let selectedAccount = {};
let calcReady = false;
let currentStatus = 'Получаем данные из Instagram...';
let newLikes = 0;
let newSubscribers = 0;
let accountGrowth = 0;

let accountInfo = {};
let periodLength = 6;

async function search(query) {
    const response = await post('/api/search', { query: query, });
    return response;
}

async function getInfo(username) {
    const response = await post('/api/get-info', { username: username, });
    return response;
}

async function selectAccount(account) {
    calcReady = false;
    showCalcPanel = true;
    selectedAccount = account;
    searchResults = [];

    const response = await getInfo(account.username);
    accountInfo = response.data;

    currentStatus = 'Определяем тип аккаунта…';

    setTimeout(() => {
        currentStatus = 'Определяем целевые аудитории…';
    }, 3000);

    setTimeout(() => {
        currentStatus = 'Выбираем тип продвижения…';
    }, 4500);

    setTimeout(() => {
        currentStatus = 'Готово!';
    }, 5500);

    setTimeout(() => {
        calcNewValues();

        calcReady = true;
        currentStatus = 'Получаем данные из Instagram...';
    }, 6500);
}

function calcNewValues() {
    newLikes = periodLength * 30 * 30;
    newSubscribers = periodLength * 30 * 25;
    accountGrowth = (newSubscribers / accountInfo.followers_count * 100).toFixed(2);
}

function debounce(func, timeout = 500){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

function onSliderChange(event) {
    periodLength = Number(event.detail.value);
}

$: periodLength && calcNewValues();

async function makeSearchRequest(){
    const result = await search(queryString);
    searchResults = result.data;
}
const onQueryInput = debounce(() => makeSearchRequest());

</script>

<div class={`${showCalcPanel ? 'w-calc-panel' : '' } widget-wrap`}>
        <div class="row top-block">
            <div class="col-sm-7 text">
                <div>
                    Сколько подписчиков и лайков получит
                </div>
                <div>
                    Ваш Instagram*-аккаунт с Zengram?
                </div>
            </div>
            <div class="col-sm-5 search">
                <div class={`auto-complete ${searchResults.length > 0 ? 'open' : ''}`}>
                    <div class="background"></div>
                    <div class="input-container">
                        <input type="text" bind:value={queryString} class="form-control" placeholder="@username" autocomple="off" on:input={onQueryInput}>
                        <i class="fa fa-search"></i>
                        <ul class="results-container">
                            {#each searchResults as item}
                                <li class={`${false ? 'selected' : ''} results-container_item`} on:click|preventDefault={() => selectAccount(item)}>
                                    <img src={item.photo} alt={item.name}>@{item.username}
                                </li>
                            {/each}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class={`row bottom-block ${showCalcPanel ? '' : 'hide'}`}>
            <div class={`loading-wrap ${calcReady ? 'hide' : ''}`}>
                <div style="margin-right: 30px;">
                    <svg class="loading-icon spinner" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.9448 16.4606C34.6348 10.1343 30.8112 4.2229 25.1277 1.52645C18.9274 -1.27371 11.3837 -0.132901 6.21681 4.2229C1.25659 8.37128 -1.01685 15.1124 0.429886 21.4387C1.77328 27.6613 6.63016 32.743 12.7271 34.4024C20.0641 36.4766 26.7811 32.9504 30.9146 26.9353C27.1944 31.9133 21.5108 35.232 15.2072 34.0912C8.9036 32.9504 3.7367 28.1798 2.28997 21.9572C0.7399 15.3198 3.84004 8.37128 9.7303 4.94887C15.8272 1.42274 24.4043 2.77097 28.4345 8.78612C29.4678 10.2381 30.1912 12.0011 30.5012 13.7642C30.8112 15.2161 30.7079 16.7718 30.9146 18.2237C31.1213 19.5719 32.258 21.335 33.808 20.4016C35.1514 19.5719 35.0481 17.8088 34.9448 16.4606C35.0481 17.1866 34.9448 16.0458 34.9448 16.4606Z" fill="#757575"/>
                    </svg>
                </div>
                <div>
                    {currentStatus}
                </div>
            </div>
            <div class={`calculations-wrap ${calcReady ? '' : 'hide'}`}>
                <div class="flex stats-wrap">
                    <div class="user-wrap flex">
                        <div class="photo-wrap">
                            <img src={selectedAccount.photo} alt={selectedAccount.name}>
                        </div>
                        <div class="username-wrap">
                            @{selectedAccount.username}
                        </div>
                    </div>
                    <div>
                        <div class="stat-value">
                            + {newLikes}
                        </div>
                        <div class="stat-label">
                            новых лайков
                        </div>
                    </div>
                    <div>
                        <div class="stat-value">
                            + {newSubscribers}
                        </div>
                        <div class="stat-label">
                            новых подписчиков
                        </div>
                    </div>
                    <div>
                        <div class="stat-value">
                            + {accountGrowth}%
                        </div>
                        <div class="stat-label">
                            рост аккаунта
                        </div>
                    </div>
                </div>
                <div class="range-wrap">
                    <RangeSlider min={1}
                                 max={12}
                                 step={1}
                                 values={[6]}
                                 pips={true}
                                 pipstep={3}
                                 all="label"
                                 on:change={onSliderChange}
                    />
                </div>
                <div class="proceed-wrap flex">
                    <a aria-label="link" href="#" class="btn header__btn3 arial_to_helvetica helvetica_light try_for_free reg_auto sticky-button font-loaded">
                        Начать бесплатное продвижение
                    </a>
                </div>
            </div>
        </div>
</div>


<style>
    .proceed-wrap {
        padding-top: 10px;
        justify-content: center;
    }
    .stats-wrap {
        justify-content: space-evenly;
        margin-bottom: 30px;
    }
    .stat-value {
        font-family: 'HelveticaNeueCyr';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        text-align: center;
        color: #00BF51;
    }
    .stat-label {
        font-family: 'HelveticaNeueCyr';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        color: #000000;
    }
    .rangeSlider .rangeHandle.active .rangeNub {
        background-color: #58bc4e;
    }
    .widget-wrap .calculations-wrap {
        padding-top: 10px;
    }
    .widget-wrap .user-wrap {
        justify-content: center;
        align-items: center;
    }
    .widget-wrap .photo-wrap img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        box-sizing: border-box;
    }
    .widget-wrap .range-wrap {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .flex {
        display: flex;
    }
    .spinner {
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;
    }
    @-moz-keyframes spin {
        100% { -moz-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform:rotate(360deg);
        }
    }
    .hide {
        display: none;
    }
    .widget-wrap .loading-wrap {
        display: flex;
        justify-content: center;
        padding: 70px 20px;

        font-family: 'HelveticaNeueCyr';
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        text-align: center;
        color: #757575;
    }
    .w-calc-panel {
        min-height: 271px;
        background: #F8F8F8;
        border: 1px solid #CECECE;
        border-radius: 11px;
    }
    .widget-wrap {
        padding: 0 15px;
    }
    .widget-wrap .top-block {
        position: relative;
        background: linear-gradient(90deg, #2d8c2b, #58bc4e, #56cc43);
        border-radius: 10px;
        padding: 20px 0;
        z-index: 1;
    }
    .widget-wrap .top-block .text {
        color: #fcfcfc;
        font-size: 24px;
        text-align: center;
    }
    .widget-wrap .top-block .search {
        margin-top: 15px;
    }

    .widget-wrap .results-container {
        position: absolute;
        z-index: 1;
        background-color: #FFFFFF;
        margin: 5px;
        min-width: 255px;
        padding: 0;
    }

    .widget-wrap .results-container .results-container_item {
        background-color: grey;
        color: #fff;
        border-bottom: none;
        padding: 10px 20px;
        cursor: pointer;
        margin: 0;
    }

    .widget-wrap .results-container .results-container_item:hover {
        background-color: #009c41;
    }

    .widget-wrap .results-container .results-container_item img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 10px;
        box-sizing: border-box;
    }

</style>