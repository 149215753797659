<script>
    import { onMount, onDestroy } from 'svelte';

    let timeout;
    onMount(() => {
        timeout = setTimeout(() => {
            window.location.href = cpaUrl;
        }, 7000);
    })

    onDestroy(() => {

    })
    export let locale = 'ru-RU';

    export let headBackgroundColor = '#43C079';
    export let headBorderBottomColor = '#43C079';

    export let cpaUrl = 'https://zencpa.ru';

    export let show = false;

    function hide() {
        show = false;
    }

</script>

{#if show}
<div class="zencpa-modal-wrap">
    <div class="zencpa-modal-header" style={`
        background-color: ${headBackgroundColor};
        border-bottom: 1px solid ${headBorderBottomColor};
    `}>
        <div class="title-wrap">
            Зарабатывайте вместе с нами в ZenCpa!
        </div>
        <a href="/nojs.html" class="close-btn-wrap" on:click|preventDefault={hide}>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_82)">
                    <path d="M8.40933 7L12.7093 2.71C12.8976 2.5217 13.0034 2.2663 13.0034 2C13.0034 1.7337 12.8976 1.47831 12.7093 1.29C12.521 1.1017 12.2656 0.995911 11.9993 0.995911C11.733 0.995911 11.4776 1.1017 11.2893 1.29L6.99933 5.59L2.70933 1.29C2.52103 1.1017 2.26563 0.995911 1.99933 0.995911C1.73303 0.995911 1.47763 1.1017 1.28933 1.29C1.10102 1.47831 0.995237 1.7337 0.995237 2C0.995237 2.2663 1.10102 2.5217 1.28933 2.71L5.58933 7L1.28933 11.29C1.1956 11.383 1.12121 11.4936 1.07044 11.6154C1.01967 11.7373 0.99353 11.868 0.99353 12C0.99353 12.132 1.01967 12.2627 1.07044 12.3846C1.12121 12.5064 1.1956 12.617 1.28933 12.71C1.38229 12.8037 1.49289 12.8781 1.61475 12.9289C1.73661 12.9797 1.86732 13.0058 1.99933 13.0058C2.13134 13.0058 2.26205 12.9797 2.38391 12.9289C2.50576 12.8781 2.61637 12.8037 2.70933 12.71L6.99933 8.41L11.2893 12.71C11.3823 12.8037 11.4929 12.8781 11.6148 12.9289C11.7366 12.9797 11.8673 13.0058 11.9993 13.0058C12.1313 13.0058 12.262 12.9797 12.3839 12.9289C12.5058 12.8781 12.6164 12.8037 12.7093 12.71C12.8031 12.617 12.8775 12.5064 12.9282 12.3846C12.979 12.2627 13.0051 12.132 13.0051 12C13.0051 11.868 12.979 11.7373 12.9282 11.6154C12.8775 11.4936 12.8031 11.383 12.7093 11.29L8.40933 7Z" fill="#F4F5F7"/>
                </g>
                <defs>
                    <clipPath id="clip0_1_82">
                        <rect width="14" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </a>
    </div>
    <div class="zencpa-modal-body">
        <p class="intro-text">
            Теперь раздел партнерской программы находится на сайте zencpa.ru!<br />
            Переходите и начинайте зарабатывать!
        </p>

        <div class="button-wrap">
            <a href={cpaUrl} class="link-btn">Перейти на ZenCpa</a>
        </div>

        <p class="outro-text">
            Вы будете перенаправлены автоматически через 7 секунд.
        </p>
    </div>
</div>
{/if}

<style>
    .zencpa-modal-header {
        padding-top: 20px;
        padding-bottom: 20px;

        color: #FFF;
        text-align: center;
        font-size: 20px;

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }
    .zencpa-modal-body {
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .intro-text, .outro-text {
        color: #000;
        font-size: 16px;
        font-weight: 300;
        line-height: 25px;
        letter-spacing: 0.24px;
        margin-bottom: 18px;

        text-align: center;
    }
    .button-wrap {
        margin-bottom: 18px;
    }
    a.link-btn:hover, a.link-btn:focus {
        text-decoration: none;
    }
    .link-btn {
        border-radius: 3px;
        background: #43C079;
        box-shadow: 0px 4px 10px 0px rgba(0, 149, 246, 0.25);

        width: 246px;
        height: 40px;
        padding-top: 12px;
        font-size: 14px;
        padding-bottom: 12px;
        margin-left: auto;
        margin-right: auto;
        display: block;
        text-align: center;
        color: #fff;
    }
    .zencpa-modal-wrap {
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

        width: 544px;
        height: 309px;
        overflow: hidden;

        font-family: Roboto;
    }

    @-moz-keyframes spin {
        100% { -moz-transform: rotate(360deg); }
    }
    @-webkit-keyframes spin {
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform:rotate(360deg);
        }
    }
    .spinner {
        -webkit-animation:spin 2s linear infinite;
        -moz-animation:spin 2s linear infinite;
        animation:spin 2s linear infinite;
    }


</style>