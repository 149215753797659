<script>
    import Auth from "./helpers/Auth";
    import Messages from "./helpers/messages/Messages";

    export let language = 'en-US';
    export let token = null;
    export let auth_endpoint = '/auth/';

    export let csrfKey = '_csrf';
    export let csrfValue = null;

    Messages.registerLanguageVersion(language);

    let password;

    let mainError = null;
    let passwordError = null;

    let loading = false;

    const auth = new Auth({
        auth_endpoint
    });

    const onSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        passwordError = null;

        try {
            const response = await auth.savePassword(password, token, csrfKey, csrfValue);
            if (response.status === 'ok') {
                window.location.href = '/auth/login';
            }
        } catch (error) {
            if (error.response.data.errors) {
                if (error.response.data.errors.password) {
                    passwordError = error.response.data.errors.password;
                }
            }
        }
    }
</script>

<header>
    <a href="/" class="logo {language}"></a>
</header>

<div class="clean-auth-form">

        <form class="auth-form" on:submit = {onSubmit}>
            {#if mainError}
                <div class="error">{mainError}</div>
            {/if}

            <h1 class="text-center">
                {Messages.t('change_form', 'TITLE')}
            </h1>

            <p class="text-center">{Messages.t('change_form', 'MESSAGE')}</p>

            <div class="field-node" class:error = {passwordError}>
                <input placeholder={Messages.t('form', 'PLACEHOLDER_PASSWORD')} type="password" name="password" id="password" autocomplete="off" bind:value={password} />
                {#if passwordError}
                    <div class="error">{passwordError}</div>
                {/if}
            </div>

            <div class="field-node">
                <button class:loading = {loading}>{Messages.t('change_form', 'BUTTON')}</button>
            </div>
        </form>
</div>

<style lang="scss">
  @import "style";

  .logo {
    &.ru-RU {
      background: url(https://auth.instashpion.ru/themes/zengram/logo.png) no-repeat center;
    }

    &.en-US {
      background: url(https://auth.instashpion.ru/themes/zenpromo/logo.png) no-repeat center;
    }
  }
</style>